import React from "react";

const Button = (props: any) => {
    return (
            <button className="btn btn-success mt-5" type="submit" onClick={() => props.onClick()}>
                {props.text}
            </button>
    );
};

export default Button;