import { AboutImageListItem } from "../components/Modules/AboutImageList";
import { AboutListItem } from "../components/Modules/AboutList";
import { AboutSummaryListItem } from "../components/Modules/AboutSummary";

export const StringData = {
    HeaderTitle: "Ministerio Global El Cuerpo de Cristo, Inc.",
    WelcomeTitle: "Ayudando a liberar a los cautivos",
    WelcomeMessage: "Rescatar a víctimas del tráfico sexual y violencia doméstica",
    WelcomeButton: "",
    AboutTitle: "¿Quiénes somos?",
    AboutMessage: "El Ministerio Global El Cuerpo de Cristo, Inc. tiene el objetivo de proporcionar un lugar seguro para victimas rescatadas de la industria de tráfico sexual y víctimas de violencia doméstica.",
    AboutButton: "Conoce Mas Acerca De Nosotros",
    AboutUsTitle: "¿Coma Lo Logramos?",
    ContactUsTitle: "Contact Us",
    ContactUsHead: "Learn about how you can help",
    ContactUsMessage: "Email or call us with any questions, and we will get back to you as soon as we can.",
    ContactUsHead2: "About your gift",
    ContactUsMessage2: "The Body of Christ Ministries Global, Inc. is a 501(c)3 organization recognized by the IRS. All contributions to The Body of Christ Ministries Global, Inc. are income tax deductible and are made with the understanding that The Body of Christ Ministries Global, Inc., under the guidance of its Advisory Board, will assure that all donations/contributions are 100% invested in fulfilling the vision of The Body of Christ Ministries Global, Inc. as written.",
    FooterTitle: "The Body of Christ Ministries Global, Inc.",
    PhoneNumber: "(903) 439-5113",
    Email: "bocmin22@gmail.com",
    Copywright: "Copyright © 2023 The Body of Christ Ministries Global, Inc. - All Rights Reserved."
}

export const SiteLinks = [
    {
        id: 4,
        path: "/es/home",
        text: "Home"
    },
    {
        id: 5,
        path: "/es/about",
        text: "About"
    },
    {
        id: 2,
        path: "/es/contact",
        text: "Contact Us",
    },
    {
        id: 2,
        path: "/",
        text: "English",
    }
];

export const WhatWeOffer: AboutSummaryListItem[] = [
];

export const TheVisionTitle: string = "¿Cuál es la Visión?";
export const TheVision: string = "La visión es tener un lugar seguro para las víctimas rescatadas de la industria del tráfico sexual y víctimas de violencia doméstica, para que puedan recuperar su dignidad y su verdadero valor personal.";
export const FutureEndeavors = " as well as women being released from prison with NO WHERE to go. Instead of the predators that are waiting for them, taking them in and using them, we want to offer them an opportunity to recover what Satan has stolen";

export const AboutUsItems: AboutImageListItem[] = [
    {
        Title: "Seguridad",
        Details: (<>Brindarles un ambiente Seguro para que puedan experimentar el amor de un Padre Celestial.</>),
        Source: require("../images/Key.jpg")
    },
    {
        Title: "Refugio",
        Details: (<>Proporcionarles no solo refugio sino también la oportunidad de hacer una nueva vida y todo lo que eso pueda implicar.</>),
        Source: require("../images/House.jpg")
    },
    {
        Title: "Guía",
        Details: (<>Proporcionar guía espiritual en una iglesia local, educación vocacional a través de cualquier manera que esté disponible.</>),
        Source: require("../images/Bible.jpg")
    },
    {
        Title: "Servicios Legales y Médicos",
        Details: (<>Ayudar con los servicios legales y médicos según sea necesario a través de cualquier vía disponible.</>),
        Source: require("../images/Doctor.jpg")
    },
    {
        Title: "Necesidades Físicas",
        Details: (<>Casa, ropa y comida para alimentar al huésped con el fin de contribuir a su salud, bienestar espiritual, emocional y físico.</>),
        Source: require("../images/Food.jpg")
    }
];
