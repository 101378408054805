import { AboutImageListItem } from "../components/Modules/AboutImageList";
import { AboutListItem } from "../components/Modules/AboutList";
import { AboutSummaryListItem } from "../components/Modules/AboutSummary";

export const StringData = {
    HeaderTitle: "The Body of Christ Ministries Global, Inc.",
    WelcomeTitle: "Helping Set the Captives Free",
    WelcomeMessage: "Rescuing victims from sex trafficking and domestic violence",
    WelcomeButton: "",
    AboutTitle: "Who We Are",
    AboutMessage: "The Body of Christ Ministries Global, Inc. focus is to provide a safe place for rescued victims of the sex trafficking industry and victims of domestic violence.",
    AboutButton: "Learn More",
    AboutUsTitle: "How do we accomplish it?",
    ContactUsTitle: "Contact Us",
    ContactUsHead: "Learn about how you can help",
    ContactUsMessage: "Email or call us with any questions, and we will get back to you as soon as we can.",
    ContactUsHead2: "About your gift",
    ContactUsMessage2: "The Body of Christ Ministries Global, Inc. is a 501(c)3 organization recognized by the IRS. All contributions to The Body of Christ Ministries Global, Inc. are income tax deductible and are made with the understanding that The Body of Christ Ministries Global, Inc., under the guidance of its Advisory Board, will assure that all donations/contributions are 100% invested in fulfilling the vision of The Body of Christ Ministries Global, Inc. as written.",
    FooterTitle: "The Body of Christ Ministries Global, Inc.",
    PhoneNumber: "(903) 439-5113",
    Email: "bocmin22@gmail.com",
    Copywright: "Copyright © 2023 The Body of Christ Ministries Global, Inc. - All Rights Reserved."
}

export const SiteLinks = [
    {
        id: 4,
        path: "/",
        text: "Home"
    },
    {
        id: 5,
        path: "/about",
        text: "About"
    },
    {
        id: 2,
        path: "/contact",
        text: "Contact Us",
        icon: "bi bi-calendar-plus"
    }
];

export const WhatWeOffer: AboutSummaryListItem[] = [
];

export const TheVisionTitle: string = "What is the Vision?";
export const TheVision: string = "The vision is to have a safe place for rescued victims of the sex trafficking industry and victims of domestic violence, in order for them to recover their dignity and true self-worth.";
export const FutureEndeavors = " as well as women being released from prison with NO WHERE to go. Instead of the predators that are waiting for them, taking them in and using them, we want to offer them an opportunity to recover what Satan has stolen";

export const AboutUsItems: AboutImageListItem[] = [
    {
        Title: "Safety",
        Details: (<>Afford them a safe environment to experience the love of a Heavenly Father.</>),
        Source: require("../images/Key.jpg")
    },
    {
        Title: "Shelter",
        Details: (<>Provide not just shelter but an opportunity to make anew their lives in whatever that may entail.</>),
        Source: require("../images/House.jpg")
    },
    {
        Title: "Guidance",
        Details: (<>Provide spiritual guidance through the local church, vocational education through whatever avenue available.</>),
        Source: require("../images/Bible.jpg")
    },
    {
        Title: "Legal and Medical Services",
        Details: (<>Assist with legal and medical services as needed through whatever avenue is available.</>),
        Source: require("../images/Doctor.jpg")
    },
    {
        Title: "Physical Needs",
        Details: (<>House, clothe and feed the guests in order to contribute to their spiritual, emotional and physical health and well-being</>),
        Source: require("../images/Food.jpg")
    }
];
