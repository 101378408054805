import React from "react";
import Welcome from "../components/Modules/Welcome";
import AboutSummary from "../components/Modules/AboutSummary";
import { StringData, WhatWeOffer } from "../data/SiteData";
import { StringData as ESStringData, WhatWeOffer as ESWhatWeOffer } from "../data/ESSiteData";

const Home = (props: any) => {
    return (
        <>
        <Welcome Title={StringData.WelcomeTitle} Message={StringData.WelcomeMessage} ShowButton={false} ButtonText={StringData.WelcomeButton} ESTitle={ESStringData.WelcomeTitle} ESMessage={ESStringData.WelcomeMessage} ESButtonText={ESStringData.WelcomeButton} />
        <AboutSummary Title={StringData.AboutTitle} Message={StringData.AboutMessage} ShowButton ButtonText={StringData.AboutButton} Items={WhatWeOffer} ESTitle={ESStringData.AboutTitle} ESMessage={ESStringData.AboutMessage} ESButtonText={ESStringData.AboutButton} ESItems={ESWhatWeOffer} />
        </>
    );
}

export default Home;