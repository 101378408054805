import React from "react";
import { AboutUsItems, StringData, TheVision, TheVisionTitle, WhatWeOffer } from "../data/SiteData";
import { StringData as ESStringData, AboutUsItems as ESAboutUsItems, TheVision as ESTheVision, TheVisionTitle as ESTheVisionTitle, WhatWeOffer as ESWhatWeOffer } from "../data/ESSiteData";
import AboutSummary from "../components/Modules/AboutSummary";
import AboutImageList from "../components/Modules/AboutImageList";
import Button from "../components/Inputs/Button";
import { useNavigate } from "react-router-dom";

const About = (props: any) => {
    let navigate = useNavigate();

    return (
        <>
        <div className="page_header"></div>
        <AboutSummary Title={StringData.AboutTitle} Message={StringData.AboutMessage} ShowButton={false} ButtonText={StringData.AboutButton} Items={WhatWeOffer} ESTitle={ESStringData.AboutTitle} ESMessage={ESStringData.AboutMessage} ESButtonText={ESStringData.AboutButton} ESItems={ESWhatWeOffer} />
        <AboutSummary Title={TheVisionTitle} Message={TheVision} ShowButton={false} ButtonText={StringData.AboutButton} Items={WhatWeOffer} ESTitle={ESTheVisionTitle} ESMessage={ESTheVision} ESButtonText={ESStringData.AboutButton} ESItems={ESWhatWeOffer} />
        <AboutImageList Title={StringData.AboutUsTitle} Items={AboutUsItems} ESTitle={ESStringData.AboutUsTitle} ESItems={ESAboutUsItems} />
        <div className="centered mt-5">
            <Button success text="Donate" onClick={() => navigate("/contact")} />
        </div>
        </>
    );
}

export default About;